$primary: #6f069e;
$white: #FFFFFF;
$link-color: $primary;
$brand-primary: $primary;
 
$font-family-sans-serif: 'Open Sans', sans-serif;

$border-radius:    0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

.pos-rel{
	position: relative;
}

.pos-abs{
	position: absolute;
}