@media (max-width: 1199.98px) {
	#intro{
		.content{
			max-width: 65%;
			padding: 22% 0;
			h1{
				font-size: 55px;
			}
			p{
				font-size: 15px;
			}
			span{
				font-size: 13px;
				line-height: 13px;
			}
		}
	}

	#subpage_intro{
		.content{
			max-width: 65%;
			h1{
				font-size: 42px;
			}
			p{
				font-size: 15px;
				margin-top: 15px;
			}
		}
	}

	.btn{
		font-size: 14px;
	}
	.btn-sm{
		font-size: 13px;
	}

	#book{
		margin-top: 90px;
		margin-bottom: -50px;
		h4{
			font-size: 36px;
		}
		h3{
			font-size: 20px;
		}
		span.slogan{
			font-size: 18px;
		}
		.imgWrap{
			top: -60px;
		}
		.textWrap{
			padding-top: 40px;
		}
	}

	#events{
		.card{
			.card-title{
				font-size: 36px;
			}
		}
	}

	#blog{
		.card{
			.card-title{
				font-size: 16px;
			}
			.type{
				font-size: 13px;
			}
			.imgWrap{
				min-height: 290px;
				min-width: 290px;
			}
		}
	}

	#cta{
		h3{
			font-size: 26px;

		}
	}

	.navbar-nav{
		.nav-item{
			.nav-link{
				font-size: 13px;
				letter-spacing: 0px;
			}
		}
	}
}

@media (max-width: 991.98px) {
	.navbar{
		padding: 0.5rem 0;
		.navbar-toggler{
			margin-right: 15px;
			outline: 0;
			&[aria-expanded=true]{
				border-color: $white;
			}
		}
		.navbar-brand{
			margin-left: 15px;
		}
		.navbar-collapse{
			background: $white;
			.navbar-nav{
				.dropdown-toggle{
					&::after{
						position: relative;
						top: 1px;
					}
				}
				.dropdown-menu{
					background: $gray-100;
					border: none;
					padding: 0;
					a{
						font-size: 12px;
					}
				}
				.nav-item{
					border-bottom: 1px solid $gray-200;
					.nav-link{
						color: $primary;
						padding: 12px 15px 9px;
						line-height: 13px;
						display: block;
						.lang{
							padding: 0;
							font-size: 13px;
							line-height: 13px;
							top: initial;
						}
					}
				}
			}
		}
	}

	#intro{
		.content{
			max-width: 85%;
			h1{
				font-size: 48px;
			}
			p{
				font-size: 14px;
			}
		}
	}

	#subpage_intro{
		.content{
			max-width: 85%;
			h1{
				font-size: 38px;
			}
			p{
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}

	#brands{
		.container{
			max-width: 100%;
		}
	}

	.sectionTitle{
		font-size: 24px;
		line-height: 24px;
	}

	#about{
		h3{
			font-size: 24px;
		}
	}

	p{
		font-size: 14px;
	}

	body{
		font-size: 14px;
	}
	
	#quote{
		h4{
			font-size: 22px;
		}
	}

	#book{
		margin-top: 70px;
		margin-bottom: -35px;
		h4{
			font-size: 30px;
		}
		h3{
			font-size: 17px;
		}
		span.slogan{
			font-size: 16px;
		}
		.imgWrap{
			top: -30px;
		}
		.textWrap{
			padding-top: 20px;
		}
		p{
			font-size: 13px;
			margin: 25px 0;
		}
	}

	.pb-5, .py-5 {
	    padding-bottom: 2rem !important;
	}

	.pt-5, .py-5 {
	    padding-top: 2rem !important;
	}

	.mb-5, .my-5 {
	    margin-bottom: 2rem !important;
	}

	.mt-5, .my-5 {
	    margin-top: 2rem !important;
	}

	#events{
		.slick-dots{
			bototm: 12px;
		}
		.card{
			h6{
				font-size: 14px;
			}
			.card-title{
				font-size: 28px;
			}
		}
	}

	.card{
		p{
			font-size: 13px;
		}
	}

	#cta{
		h3{
			font-size: 24px;
		}
		p{
			font-size: 13px;
		}
	}

	aside#sidebar {
	    font-size: 13px;
	}

	.contact{
		.pl-0{
			padding-left: 15px !important;
		}
	}

}

@media (max-width: 767.98px) {
	.blog-section-1,
	.blog-section-2,
	.blog-section-3{
		margin-top: 0 !important;
	}
	.container{
		max-width: 100% !important;
	}

	#intro{
		.content{
			padding: 22% 0 13%;
			h1{
				font-size: 40px;
			}
			p{
				font-size: 12px;
			}
		}
		.btn{
			margin-top: 1rem !important;
		}
	}

	.btn{
		font-size: 12px;
	}

	.navbar-brand{
		img{
			max-width: 160px;
		}
	}
	.sectionTitle {
	    font-size: 22px;
	    line-height: 22px;
	}

	#about{
		text-align: center;
		.imgWrap{
			margin-bottom: 25px;
			max-width: 50%;
			img{
				max-width: 100%;
			}
		}
		a{
			display: inline-block;
		}
		p{
			text-align: left;
		}
		h3{
			font-size: 22px;
		}
		.align-self-center{
			text-align: center;
		}
	}

	.card{
		a{
			.card-body{
				padding: 0.75rem;
			}
			.card-title{
				font-size: 15px;
			}
			p{
				font-size: 12px;
			}
		}
	}

	#services{
		#servicesCarousel{
			padding-bottom: 15px;
		}
	}

	#videos{
		.videosCarousel{
			padding-bottom: 15px;
		}
		.card{
			a{
				.card-body{
					padding: 1rem;
				}
			}
		}
	}

	#quote {
	    h4{
	    	font-size: 18px;
	    }
	    small{
	    	font-size: 14px;
	    }
	}

	#book{
		margin-top: 74px;
	    margin-bottom: -50px;
		.imgWrap{
			top: -50px;
		}
		h4{
			font-size: 26px;
		}
		h3{
			font-size: 15px;
		}
		span.slogan{
			font-size: 14px;
		}
		p{
			font-size: 13px;
		}
	}

	.aboutpage-in{
		aside#sidebar{
			text-align: center;
			display: block;
			margin-bottom: 20px;
			.imgWrap{
				width: 50%;
				display: inline-block;
				margin-bottom: 0;
			}
			.list-group{
				a.list-group-item{
					font-size: 13px;
					padding: 10px 10px;
				}
			}
		}
	}

	#talks{
		.card{
			.card-title{
				font-size: 18px;
			}
		}
	}

	.contact{
		#map{
			height: 250px;
		}
	}

	.contact{
		.quick-contact{
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}

	#breadcrumb{
		nav{
			ol.breadcrumb{
				padding: 0.75rem 0;
				li.breadcrumb-item{
					font-size: 12px;
					a{
						font-size: 12px;
					}
				}
			}
		}
	}

	#subpage_intro{
		.content{
			h1{
				font-size: 34px;
			}
			p{
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 630px) {
	#book{
		margin-top: 60px;
	    margin-bottom: -40px;
		.imgWrap{
			top: -40px;
		}
		h4{
			font-size: 24px;
		}
		h3{
			font-size: 14px;
		}
		span.slogan{
			font-size: 13px;
		}
		p{
			font-size: 12px;
		}
	}

	#subpage_intro{
		.content{
			padding: 20% 0 5%;
		}
	}
}

@media (max-width: 575.98px) {
	#intro{
		.content{
			padding: 30% 0 18%;
			max-width: 100%;
			h1{
				font-size: 32px;
			}
			span{
				font-size: 12px;
			}
		}
	}

	p{
		font-size: 12px;
	}

	#quote {
	    h4{
	    	font-size: 16px;
	    	br{
	    		content: '';
	    		position: relative;
	    	}
	    }
	    small{
	    	font-size: 13px;
	    }
	}

	#book{
		margin-top: 0;
	    margin-bottom: 0;
	    padding-top: 2rem;
	    padding-bottom: 2rem;
	    .textWrap{
	    	text-align: center;
	    }
		.imgWrap{
			top: 0;
			text-align: center;
			img{
				max-width: 60%;
			}
		}
		h4{
			font-size: 26px;
		}
		h3{
			font-size: 17px;
			-ms-grid-columns: 1fr auto 1fr;
		    grid-template-columns:1fr auto 1fr;
			&:after,
			&:before{
				background: $gray-700;
			    width: 100%;
			    height: 1px;
			    content: '';
			    opacity: 0.3;
			}
		}
		span.slogan{
			font-size: 16px;
		}
		p{
			margin: 20px 0;
		}
	}

	.card{
		a{
			.card-title{
				font-size: 14px;
			}
		}
	}

	#events{
		.card{
			p{
				padding: 0 0;
				font-size: 12px;
				margin-top: 20px;
			}
			.card-title{
				font-size: 24px;
			}
		}
	}
	#blog{
		.card{
			.card-title{
				font-size: 14px;
			}
		}
	}

	#cta{
		h3{
			font-size: 22px;
		}
		p{
			font-size: 12px;
		}
		a.btn{
			margin-top: 0.5rem !important;
		}
	}

	.footer-navigation{
		display: none;
	}

	#blog{
		.card{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	#contact{
		.contact-form{
			padding: 15px;
			margin: 0 -15px;
			.form-control{
				font-size: 12px;
			}
		}
	}

	#subpage_intro{
		.content{
			min-width: 100%;
			padding: 28% 0 5%;
			h1{
				font-size: 30px;
			}
			p{
				display: none;
			}
		}
	}

	#talks{
		.card{
			.card-title {
			    font-size: 16px;
			}
		}
	}

}

@media (max-width: 400px) {
	#intro{
		.content{
			padding: 40% 0 25%;
			h1{
				font-size: 30px;
				i{
					display: block;
				}
			}
		}
	}

	#events{
		.card{
			p{
				font-size: 12px;
				margin-top: 20px;
			}
			.card-title{
				font-size: 20px;
			}
			h6{
				font-size: 12px;
			}
		}
	}

	#cta{
		h3{
			font-size: 20px;
		}
	}

	.aboutpage-in{
		aside#sidebar{
			margin-bottom: 0px;
			.list-group{
				a.list-group-item{
					font-size: 12px;
				}
			}
		}
	}

	#subpage_intro{
		.content{
			padding: 32% 0 5%;
			h1{
				font-size: 27px;
			}
		}
	}
}



