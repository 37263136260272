@import "_variables";
@import "_stylesheet";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";
@import "node_modules/magnific-popup/src/css/main.scss";


body{
	background: #f8f9fa;
}

p{
	font-size: 15px;
	line-height: 1.8;
	color: $gray-700;
}

a{
	outline: 0;
}

.btn{
	font-size: 15px;
}

.btn-sm{
	font-size: 13px;
}

.pagination{
	li{
		font-size: 14px;
		a{
			font-size: 14px;
		}
	}
}


.navbar{
	position: absolute;
	top: 0;
	 left: 0;
	 width: 100%;
	 z-index: 999;
	 background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	 background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
	 background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
	 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 );
}

.navbar-light{
	.navbar-nav{
		.nav-link {
		    color: $primary;
		}
	}
}

.navbar-nav{
	margin-top: 12px;
	.nav-item{
		.nav-link{
			font-size: 14px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			font-weight: 400;
			font-family: 'brandon_grotesquemedium';
			color: $white;
			&:hover{
				
			}
			.lang{
				background: $white;
				padding: 6px 10px 3px;
				border-radius: 5px;
				color: $primary;
				font-size: 12px;
				line-height: 12px;
				font-weight: 400;
				display: block;
				position: relative;
				top: -3px;
			}
		}
		&.active{
			.nav-link{

			}
		}
	}

	.dropdown-menu{
		background: $white;
		border: 1px solid$primary;
		padding: 0;
		a{
			color: $primary;
			border-bottom: 1px solid $gray-200;
			font-size: 13px;
			padding: 0.5rem 1rem;
		}
	}

	.dropdown-toggle{
		&::after{
			position: relative;
			top: 2px;
		}
	}
	
}


.sectionTitle{
	line-height: 26px;
	font-size: 26px;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-gap: 20px;
	align-items: center;
	&:before,
	&:after{
		background: $gray-300;
		width: 100%;
		height: 1px;
		content: '';
		opacity: 0.5;
	}
}

#intro, #subpage_intro{
	background: white;
	position: relative;
	background: url('/assets/bg2.png') no-repeat top center;
	background-size: cover;
	.content{
		padding: 25% 0;
		position: relative;
		z-index: 2;
		max-width: 60%;
		h1{
			font-size: 60px;
			display: block;
			font-weight: 400;
			font-family: 'Chronicle Display';
			font-style: italic;
		}
		h4{
			font-size: 60px;
			font-weight: 400;
			margin: 0;
		}
		span{
			font-size: 14px;
			line-height: 14px;
			font-weight: 300;
			margin: 10px 0 30px;
			display: block;
		}

		p{
			font-size: 16px;
			line-height: 2;
			font-weight: 300;
			margin-top: 20px;
		}
	}
	.btn{
		padding: 8px 40px;
	}
	.overlay{
		opacity: 0.9;
		background: #2a1e38; /* Old browsers */
		background: -moz-linear-gradient(left, #2a1e38 0%, #7d478c 50%, #2a1e38 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #2a1e38 0%,#7d478c 50%,#2a1e38 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #2a1e38 0%,#7d478c 50%,#2a1e38 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a1e38', endColorstr='#2a1e38',GradientType=1 ); /* IE6-9 */
	}
}

.tmp-home #intro{
	background-image: url('/assets/vedat-erol-2016.jpeg') !important;

	.container{
		display: flex;
		align-items: center;
		height: 100vh;

		.content{padding-top:85px; padding-bottom: 0;}
	}
}

#subpage_intro{
	.content{
		padding: 15% 0 5%;
		h1{
			font-size: 45px;
		}
		h4{
			font-size: 40px;
		}
		span{
			font-size: 12px;
			line-height: 12px;
		}

		p{
			font-size: 16px;
		}
	}
}

.progressingOverlay{
    background: rgba(255,255,255,0.7);
    position: fixed;
    top:0;
    left:0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: none;
    img{
        position: fixed;
        left: 50%;
        top: 50%;
        width: 80px;
        margin: -40px 0 0 -40px;
    }
}


.overlay{
	background: #7d478c; /* Old browsers */
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	opacity: 0.8;
	&.white{
		background: $white;
		opacity: 0.9;
	}
	i,svg{
		color: $white;
		font-size: 50px;
		top: 50%;
		left: 50%;
		position: absolute;
		z-index: 2;
		margin: -25px 0 0 -25px;
	}
}

.navbar-brand{
	img{
		max-width: 200px;
	}
}

.brandsCarousel{
	.item{
		text-align: center;
		display: block;
		width: 100%;
		img{
			display: inline-block
		}
	}
}

#brands {
	.carousel-indicators{
        bottom:-2rem;

        li{background-color:#DCDEDF;}
        .active{background-color: $primary}
    }
}

#about{
	.imgWrap{
		border: 1px solid $primary;
		padding: 2px;
		background: $white;
		img{
			border: 1px solid $white;
			max-width: 100%;
			-moz-transition: all 1.5s;
			-webkit-transition: all 1.5s;
			transition: all 1.5s;
			max-width: 330px;
		}
	}
	&:hover{
		.imgWrap{
			img{
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
	&.aboutpage-in{
		.imgWrap{
			img{
				max-width: 100%;
			}
		}
		&:hover{
			.imgWrap{
				img{
					-moz-transform: scale(1);
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}
		}
	}
}

#services,
#videos{
	.slick-slide{
		margin: 0 15px;
	}
	.videosCarousel{
		padding-bottom: 30px;
		position: relative;
	}
}

#services{
	#servicesCarousel{
		padding-bottom: 30px;
	}
}


.characterLimit{
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
}

.card{
	a{
		text-decoration: none;
		.card-title{
			color: $gray-900;
			font-size: 16px;
		}
	}
	p{
		font-size: 14px;
	}
	.imgWrap{
		display: block;
		overflow: hidden;
		img{
			-moz-transition: all 1.5s;
			-webkit-transition: all 1.5s;
			transition: all 1.5s;
		}
		.overlay{
			opacity: 0;
			-moz-transition: all 1.5s;
			-webkit-transition: all 1.5s;
			transition: all 1.5s;
		}
	}

	&:hover{
		.imgWrap{
			img{
				-moz-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.overlay{
			opacity: 0.8;
		}
	}
}

.slick-dots{
	bottom: -36px;
	li{
		button{
			&:before{
				color: $gray-500;
				opacity: 1;
				font-size: 24px;
				transition: all 300ms linear;
			}
		}
		&.slick-active{
			button{
				&:before{
					color: $primary;
					font-size: 28px;
				}
			}
		}
	}
}

#videos{
	.card{
		.card-title{
			margin-bottom: 0;
		}
		.playIcon{
			position: absolute;
			left: 50%;
			top: 50%;
			margin: -25px 0 0 -25px;
			-moz-transition: all 1.5s;
			-webkit-transition: all 1.5s;
			transition: all 1.5s;
			opacity: 0.5;
			i,svg{
				color: $white;
				font-size: 50px;
				border-radius: 50%;
				border: 1px solid $gray-900;
				background: $gray-900;
			}
		}
		&:hover{
			.playIcon{
				opacity: 0;
			}
		}
	}
}

.honeypot {
	display: none;
}

#events{
	background: url('/assets/self-improvement-books_.jpg') no-repeat center right;
	background-size: cover;
	.sectionTitle{
		&:before,
		&:after{
			background: $primary;
			opacity: 0.2;
		}
	}

	.container{
		position: relative;
		z-index: 2;
	}
	.card{
		.card-title{
			font-size: 38px;
		}
		h6{
			font-size: 16px;
			font-weight: 300;
		}

		p{
			font-weight: 300;
			margin-top: 30px;
			padding: 0 15%;
		}
	}

	.slick-dots{
		bottom: 20px;
		li{
			button{
				&:before{
					color: $gray-600;
					opacity: 1;
					font-size: 24px;
					transition: all 300ms linear;
				}
			}
			&.slick-active{
				button{
					&:before{
						color: $white;
						font-size: 28px;
					}
				}
			}
		}
	}
}


#blog{
	.card{
		overflow: hidden;
		border: none;
		margin-bottom: 30px;
		.imgWrap{
			min-height: 330px;
			min-width: 330px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.card-title{
			font-size: 20px;
			margin-top: 10px;
			color: $gray-700;
			font-weight: 400;
		}
		.type{
			font-size: 15px;
			font-weight: 400;
			font-style: italic;
		}
		.readmore{
			font-size: 13px;
			font-weight: 400;
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 20px;
			align-items: center;
			font-style: italic;
			color: $gray-700;
			&:before{
				background: $gray-300;
				width: 30px;
				height: 1px;
				content: '';
				opacity: 0.5;
			}
		}
	}
	.blog-section-1{
		.card{
			.type{
				color: rgb(33, 150, 243);
			}
			.overlay{
				background: rgba(33, 150, 243, 0.8);
			}
		}
	}

	.blog-section-2{
		.card{
			.type{
				color: rgb(172, 55, 224);
			}
			.overlay{
				background: rgba(172, 55, 224, 0.8);
			}
		}
	}

	.blog-section-3{
		.card{
			.type{
				color: rgb(232, 32, 177);
			}
			.overlay{
				background: rgba(232, 32, 177, 0.8);
			}
		}
	}

	&.blogPage{
		.card{
			.card-title{
				font-size: 16px;
			}
			.imgWrap{
				min-height: 260px;
				min-width: 260px;
			}
			.type{
				font-size: 14px;
			}
		}
	}
}

#talks{
	.card{
		margin-bottom: 30px;
		.card-img-top{
			max-height: 226px;
		}
	}
}

#cta{
	h3{
		font-size: 28px;
	}
	p{
		font-size: 14px;
		font-weight: 400;
		color: $gray-300;
	}
	.readmore{
		font-size: 12px;
	}
}


#book{
	margin-top: 130px;
	margin-bottom: -65px;
	.imgWrap{
		position: relative;
		top: -90px;
	}
	.textWrap{
		padding-top: 60px;
	}
	h3{
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 20px;
		align-items: center;
		font-style: italic;
		color: $gray-600;
		font-size: 23px;
		margin-bottom: 15px;
		&:after{
			background: $gray-900;
			width: 30px;
			height: 1px;
			content: '';
			opacity: 0.5;
		}
	}
	h4{
		font-size: 40px;
		font-weight: 700;
	}
	p{
		font-size: 16px;
		line-height: 1.8;
		margin: 50px 0;
		color: $gray-900;
	}

	span.slogan{
		font-size: 20px;
		color: $gray-900;
		font-weight: 300;
	}
}

footer#site-footer{
	font-size: 12px;
	color: $white;
	border-top: 1px solid $gray-700;
	.copyright{
		font-size: inherit;
		padding: 0.5rem 1rem;
	}
	a{
		color: $white;
	}

	.social-footer-nav{
		border-bottom: 1px solid $gray-700;
		li{
			a{
				margin-top: 3px;
				svg,i{
					font-size: 16px;
				}
			}
		}
	}
}

#breadcrumb{
	nav{
		ol.breadcrumb{
			background: transparent;
			padding-left: 0;
			li.breadcrumb-item{
				font-size: 14px;
				a{
					font-size: inherit;
				}
			}
		}
	}
}


.videosWrapper{
	margin-bottom: -30px;
	.card{
		margin-bottom: 30px;
	}
}


aside#sidebar{
	font-size: 14px;
}



.contact{
	/* [class*=col-md]{
		padding:0px;
	} */
	.contact-form{
		background:url('/assets/contact.jpg') no-repeat center center;
		background-size:cover;
		color:#fff;
		height: 100%;
		padding: 50px 105px 20px 50px;
		h3{
			margin-bottom: 30px;
		}
		form{
			textarea{
				resize:none
			}
		}
		.form-control{
		    padding: 12px 12px;
		    font-size: 13px;
		    color: #FFF;
		    background-color: transparent;
		    background-image: none;
		    border: 1px solid #695473;
		    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		}
	}
	.recaptcha-sizer{
		max-width: 304px;
		height: 84px;
		position: relative;
		iframe{
			height: 84px;
		}
	}
	.quick-contact{
		h4{
			font-size: 20px;
			margin-bottom: 20px;
		}
		p{
			margin:10px 0px;
			line-height:1.8;
			font-size: 14px;
		}
		ul{
			margin:30px 0px 0;
			li .fa{font-size:18px;}
		}
		.input-group{
			width:260px;
			position: relative;
			.form-control{
				background:transparent;
				border-color:#474747;
			}
			.btn{
				background:transparent;
				position: absolute;
				padding:7px 10px;
				right:0px;
				top:0px;
				margin:0px;
				font-size:21px;
				z-index:3;
				&:hover{color:#eee}
			}
		}
	}
	#map{
		width: 100%;
		height: 100%;
		h4{
			font-size: 14px;
			color: $gray-700;
		}
	}
}

#quote{
	h4{
		line-height: 1.4;
	}
}

.quote-list{
	p{
		font-size: 14px;
		position: relative;
		padding: 0 20px;
		margin:  40px 10px 0 10px;
		&:before{
			position: absolute;
			left: -10px;
			top: -10px;
			content: 'â';
			display: inline-block;
			font-size: 60px;
			line-height: 14px;
			color: $primary;
			font-family: 'Times New Roman';
		}
		&:after{
			position: absolute;
			right: -10px;
			bottom: -10px;
			content: 'â';
			display: inline-block;
			font-size: 60px;
			line-height: 14px;
			color: $primary;
			font-family: 'Times New Roman';
		}
	}
}

.white-popup-block{
	background: $white;
	max-width: 700px;
	margin: 0 auto;
	padding: 20px;
	font-size: 14px;
	h1{
		color: $primary;
		font-size: 28px;

	}
}

.bussinessPartnerItem{
	img{
		max-width: 180px;
		width: 100%;
	}
}







@import "_responsive";