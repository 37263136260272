@font-face {
    font-family: 'brandon_grotesquebold_italic';
    src: url('/assets/font/brandon_bld_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_bld_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_bld_it-webfont.svg#brandon_grotesquebold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquebold';
    src: url('/assets/font/brandon_bld-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_bld-webfont.woff') format('woff'),
         url('/assets/font/brandon_bld-webfont.svg#brandon_grotesquebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueblack_italic';
    src: url('/assets/font/brandon_blk_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_blk_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_blk_it-webfont.svg#brandon_grotesqueblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueblack';
    src: url('/assets/font/brandon_blk-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_blk-webfont.woff') format('woff'),
         url('/assets/font/brandon_blk-webfont.svg#brandon_grotesqueblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquelight_italic';
    src: url('/assets/font/brandon_light_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_light_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_light_it-webfont.svg#brandon_grotesquelight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquelight';
    src: url('/assets/font/brandon_light-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_light-webfont.woff') format('woff'),
         url('/assets/font/brandon_light-webfont.svg#brandon_grotesquelight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueMdIt';
    src: url('/assets/font/brandon_med_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_med_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_med_it-webfont.svg#brandon_grotesqueMdIt') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquemedium';
    src: url('/assets/font/brandon_med-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_med-webfont.woff') format('woff'),
         url('/assets/font/brandon_med-webfont.svg#brandon_grotesquemedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueRgIt';
    src: url('/assets/font/brandon_reg_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_reg_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_reg_it-webfont.svg#brandon_grotesqueRgIt') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesqueregular';
    src: url('/assets/font/brandon_reg-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_reg-webfont.woff') format('woff'),
         url('/assets/font/brandon_reg-webfont.svg#brandon_grotesqueregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquethin_italic';
    src: url('/assets/font/brandon_thin_it-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_thin_it-webfont.woff') format('woff'),
         url('/assets/font/brandon_thin_it-webfont.svg#brandon_grotesquethin_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_grotesquethin';
    src: url('/assets/font/brandon_thin-webfont.woff2') format('woff2'),
         url('/assets/font/brandon_thin-webfont.woff') format('woff'),
         url('/assets/font/brandon_thin-webfont.svg#brandon_grotesquethin') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Chronicle Display';
    src: url('/assets/font/hinted-ChronicleDisplay-Roman.eot');
    src: url('/assets/font/hinted-ChronicleDisplay-Roman.eot?#iefix') format('embedded-opentype'),
        url('/assets/font/hinted-ChronicleDisplay-Roman.woff2') format('woff2'),
        url('/assets/font/hinted-ChronicleDisplay-Roman.woff') format('woff'),
        url('/assets/font/hinted-ChronicleDisplay-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: 'Gotham Narrow Book';
    src: url('/assets/font/subset-GothamNarrow-Book.eot');
    src: url('/assets/font/subset-GothamNarrow-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/font/subset-GothamNarrow-Book.woff2') format('woff2'),
        url('/assets/font/subset-GothamNarrow-Book.woff') format('woff'),
        url('/assets/font/subset-GothamNarrow-Book.ttf') format('truetype'),
        url('/assets/font/subset-GothamNarrow-Book.svg#GothamNarrow-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}*/